import React from 'react'
import PropTypes from 'prop-types'
import { getImage } from 'gatsby-plugin-image'
import { modifyImageKeys } from '@utils/modifyImageKeys'
import clsx from 'clsx'

import ElementImage from '@components/global/element/Image'
import ElementImageFile from '@components/global/element/ImageFile'
import * as cx from './Tablet.module.scss'

const DeviceTablet = ({
  imageSrc,
  className,
  rotate = false,
  imageFile,
  imageFileAlt = '',
  imagePlaceholder = 'https://resources.agentimage.com/redesign/local/tablet-placeholder.png',
  imagePortraitPlaceholder = 'https://resources.agentimage.com/redesign/local/tablet-portrait-placeholder.png',
  splideLazyload = false,
  lazy = true,
  width = 720,
  height = 590,
}) => {
  let image = null

  if (imageFile !== null && imageFile !== undefined) {
    if (imageFile.modified) {
      image = modifyImageKeys(imageFile)
    } else {
      image = getImage(imageFile)
    }
  }

  return (
    <div className={clsx(className, [cx.wrapper], { [cx.rotate]: rotate })}>
      <canvas
        width={rotate ? height : width}
        height={rotate ? width : height}
        style={{
          backgroundImage: `url(${
            rotate ? imagePortraitPlaceholder : imagePlaceholder
          })`,
        }}
        className={`${cx.placeholder} global-devices-tablet-placeholder`}
      ></canvas>
      {image === null && (
        <canvas
          width={rotate ? height : width}
          height={rotate ? width : height}
          style={{ backgroundImage: `url(${imageSrc})` }}
          className={cx.canvas}
        ></canvas>
      )}

      {image !== null && splideLazyload && (
        <ElementImage
          src={image?.images.fallback.src}
          width={rotate ? height : width}
          height={rotate ? width : height}
          alt={imageFileAlt}
          srcSet={image?.images.fallback.srcSet}
          sizes={image?.images.fallback.sizes}
          splideLazyload={splideLazyload}
          className={cx.image}
        />
      )}

      {image !== null && !splideLazyload && (
        <ElementImageFile
          src={image}
          width={rotate ? height : width}
          height={rotate ? width : height}
          alt={imageFileAlt}
          className={cx.image}
          lazy={lazy}
        />
      )}
    </div>
  )
}

DeviceTablet.propTypes = {
  imageSrc: PropTypes.string,
  className: PropTypes.string,
  rotate: PropTypes.bool,
  imageFile: PropTypes.object,
  imagePortraitPlaceholder: PropTypes.string,
  imageFileAlt: PropTypes.string,
  splideLazyload: PropTypes.bool,
  lazy: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
}

export default DeviceTablet
