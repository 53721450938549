import React from 'react'
import { graphql } from 'gatsby'
import {
  reconstructGalleryItems,
  reconstructProductSlideFeatures,
} from '@utils/reconstructArrayObject'

import Seo from '@components/seo/Index'
import LayoutInnerPage from '@src/layouts/InnerPage'
import Banner from '@components/global/banner/Banner'
import Ads10BestWebsites from '@components/global/ads/10BestWebsites'
import AdsCompareWebsites from '@components/global/ads/CompareWebsites'
import FormsBottomVersion2 from '@components/global/forms/bottom/Version2'

import {
  Gallery,
  FeaturesSlider,
  OnlinePresence,
  WebsiteFeatures,
} from '@components/pages/real-estate-websites'

import * as cx from './AgentImageX.module.scss'

const ProductGalleryAgentImageX = ({
  data: { page },
  pageContext: { seo, uri },
}) => {
  const product = page.product

  return (
    <LayoutInnerPage wrapperClassName={cx.wrapper}>
      <Seo title={page.title} seo={seo} uri={uri} />
      <Banner
        bgGradientShow
        wrapperClassName={cx.banner}
        // bgGradient={cx.bgGradient}
        layout="product-gallery"
        breadcrumbs={page?.seo?.breadcrumbs}
        title={page?.bannerForm?.title}
        description={page?.bannerForm?.description}
        devices={{
          layout: 'ProductGalleryPhoneTablet',
          mobile: `${page?.bannerForm?.mobile?.url}`,
          tablet: `${page?.bannerForm?.tablet?.url}`,
        }}
        formTitle={page?.bannerForm?.formTitle}
        formSubtitle={page?.bannerForm?.formSubtitle}
        formSubmitLabel={page?.bannerForm?.formSubmitLabel}
      />
      <Gallery
        title={product.gallery.title}
        description={product.gallery.description}
        posts={reconstructGalleryItems(product.gallery.items)}
        postsPerPage={4}
        loadMoreButtonTextDesktop={`View more ${page.title} websites`}
        loadMoreButtonTextMobile={`More ${page.title} websites`}
      />
      <FeaturesSlider
        items={reconstructProductSlideFeatures(product.features.items)}
      />
      <OnlinePresence
        background={product.kickstart.background.url}
        title={product.kickstart.title}
        description={product.kickstart.description}
        items={product.kickstart.items}
      />
      <WebsiteFeatures
        wrapperClassName={cx.websiteFeatures}
        bannerBg={page.productFeatures.background.url}
        title={page.title}
        headingClassName="heading-1"
        bannerLaptop={page.productFeatures.laptop.url}
        bannerMobile={page.productFeatures.mobile.url}
        bestForItems={page.productFeatures.bestFor}
        highlightsItems={page.productFeatures.highlights}
        featuresItems={page.productFeatures.more}
      />

      <div className="ads">
        <div className="container">
          <Ads10BestWebsites
            background={
              page.productAds.background.url !== null
                ? page.productAds.background.url
                : 'https://resources.agentimage.com/redesign/local/ads/aix-bg.jpg'
            }
            wrapperClassName={cx.bestWebsites}
            name={page.title}
            image={
              page.productAds.image.url !== null
                ? page.productAds.image.url
                : 'https://resources.agentimage.com/redesign/local/ads/aix-device.png'
            }
            imageWidth={
              page.productAds.image.url !== null
                ? page.productAds.image.width !== null
                : 640
            }
            imageHeight={
              page.productAds.image.url !== null
                ? page.productAds.image.height !== null
                : 388
            }
            link={page.productAds.link}
          />
          <AdsCompareWebsites />
        </div>
      </div>

      <FormsBottomVersion2 formLocation="footer" />
    </LayoutInnerPage>
  )
}

export default ProductGalleryAgentImageX

export const pageQuery = graphql`
  query ProductGalleryAgentImageXPageById($id: String!) {
    page: wpPage(id: { eq: $id }) {
      id
      content
      title
      date(formatString: "MMMM DD, YYYY")
      seo {
        breadcrumbs {
          text
          url
        }
      }
      product: productAixInputs {
        gallery {
          title
          description
          items {
            name
            url
            tablet {
              url
              srcset
              sizes
              width
              height
              alt
              modified
            }
            mobile {
              url
              srcset
              sizes
              width
              height
              alt
              modified
            }
          }
        }
        features {
          title
          items {
            title
            image {
              url
              srcset
              sizes
              width
              height
              alt
              modified
            }
          }
        }
        kickstart {
          background {
            url
          }
          title
          description
          items {
            title
            description
          }
          background {
            url
          }
        }
      }

      bannerForm {
        title
        description
        tablet {
          url
          srcset
          sizes
          width
          height
          alt
          modified
        }
        mobile {
          url
          srcset
          sizes
          width
          height
          alt
          modified
        }
        formTitle
        formSubtitle
        formSubmitLabel
      }

      productFeatures {
        background {
          url
        }
        laptop {
          url
          srcset
          sizes
          width
          height
          alt
          modified
        }
        mobile {
          url
          srcset
          sizes
          width
          height
          alt
          modified
        }
        bestFor {
          check
          content
        }
        highlights {
          check
          content
        }
        more {
          check
          content
        }
      }

      productAds {
        background {
          url
        }
        image {
          url
          width
          height
        }
        link
      }

      leadContactForm {
        background {
          url
          srcset
          sizes
          width
          height
        }
        version
        model
        title
        subtitle
        submit
      }
    }
  }
`
