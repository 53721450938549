import React from 'react'
import PropTypes from 'prop-types'

import FormFreeConsultation from '../../FreeConsultation'
import BgDefault from '/content/assets/bottom-form/alt.jpg'
import ImgModel from '/content/assets/bottom-form/alt-model.png'
import * as cx from './Version2.module.scss'

const FormsBottomVersion2 = ({
  background = `${BgDefault}`,
  model = `${ImgModel}`,
  title = 'Need help choosing a website?',
  subtitle = 'Chat with a Web Marketing Strategist.',
  formLocation = '',
}) => {
  return (
    <section className={cx.wrapper}>
      <div
        className="container"
        style={{ backgroundImage: `url(${background})` }}
      >
        <div className="row">
          <div className={`col ${cx.model}`}>
            <img src={model} alt={title} width={518} height={800} />
          </div>
          <div className={`col ${cx.form}`}>
            <h2 className="heading-3">{title}</h2>
            <p className="subtitle-3">{subtitle}</p>
            <FormFreeConsultation
              submitLabel="Schedule a consultation"
              leadName="Bottom FSU"
              isEmailPhoneMerger={true}
              isMessageTextarea={true}
              formLocation={formLocation}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

FormsBottomVersion2.propTypes = {
  background: PropTypes.string,
  model: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  formLocation: PropTypes.string,
}

export default FormsBottomVersion2
